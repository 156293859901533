.crashplayers {
  min-height: 200px;
  max-height: 400px;
  overflow-y: scroll;
  height: 80%;
  width: 100%;
  background: #252838;
  box-shadow: 0px 6px 0px #1f262e;
  border-radius: 12px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.crashplayers_header {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.crashplayers_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;

  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #f8f8ff;
}

.crashplayers_row {
  padding: 7px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.crashplayers_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.crashplayers_bet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

@media only screen and (min-width: 900px) {
  .crashplayers {
    min-height: 100px;
  }
}

@media only screen and (min-width: 1100px) {
  .crashplayers {
    min-height: 200px;
  }
}