.wrapper {
  padding: 20px;
}

.wrapper,
.wrapperGame {
  z-index: 1;
  position: relative;
  height: fit-content;
  background: #252838;
  box-shadow: 0px 6px 0px #1f262e;
  border-radius: 12px;
  max-width: 300px;
  width: 100%;
}

.wrapper h3 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #5f7381;
}

.inputWrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
  background: #212432;
  box-shadow: inset 0px -4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.wrapperGame img,
.inputWrapper img {
  width: 26px;
  height: 26px;
}

.inputWrapper img {
  position: absolute;
  left: 15px;
}

.btnWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 7px;
  margin-top: 11px;
}

.wrapperGame {
  padding: 31px 15px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.balanceWrapper {
  margin: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.balance {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.balanceLine {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wrapperGame h4 {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: #5f7381;
}

.balanceLine h3 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: #72f138;
}

.balance {
  width: 100%;
}

.multiplier {
  margin: 0;
  font-weight: 700;
  font-size: 3.625rem;
  line-height: 5rem;
  background: linear-gradient(180deg, #d3ff76 0%, #72f138 46.4%, #3bbd1b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-shadow: 0px 0px 5px rgba(98, 255, 95, 0.8),
    0px 0px 30px rgba(98, 255, 95, 0.3);
}

.multiplierRed {
  font-weight: 700;
  font-size: 3.625rem;
  line-height: 5rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0;

  background: linear-gradient(180deg, #ff8166 0%, #fc6551 46.4%, #f13838 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  text-shadow: 0px 0px 5px rgba(241, 56, 56, 0.8),
    0px 0px 30px rgba(241, 56, 56, 0.3);
}

.gameOver {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #f8f8ff;
}

.inputAd {
  height: 46px;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #72f138;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 51px;
  border-width: 0;
  border-color: none;
}

.btnInputWrapper {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.btnInput {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background: #353749;
  border-radius: 4px;
  transition: 0.2s;
}

.btnInput:hover {
  opacity: 0.5;
}

.btnInput p {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #5f7381;
}

.btnInput > img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 20px;
  height: 20px;
}

.primaryBtn {
  height: 55px;
  width: 100%;
  margin-top: 20px;
  background: linear-gradient(
      180deg,
      #72f138 -98.76%,
      rgba(114, 241, 56, 0) 134.37%
    ),
    #72f138;
  box-shadow: 0px 0px 5px rgba(98, 255, 95, 0.8),
    0px 0px 30px rgba(98, 255, 95, 0.3), inset 0px 0px 12px #5fff6f;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.primaryBtn:enabled:hover {
  opacity: 0.5;
}

.primaryBtn:disabled {
  background: #5f7381;
  box-shadow: none;
}

.primaryBtn p {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #002800;
}

.customSelect {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

.selectOption {
  opacity: 0;
  position: absolute;
  left: -99999px;
}

.optionLabel {
  padding: 0 30px 0 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;
  order: 2;
  z-index: 5;
  transition: 0.2s;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  background: #212432;
  color: #72f138;
  cursor: pointer;
}

.customSelect:focus label {
  position: relative;
  pointer-events: all;
}

.optionLabel:hover {
  background: #353749;
  color: #5f7381;
}

.selectOption:checked + label {
  order: 1;
  z-index: 6;
  position: relative;
  border-radius: 8px;
  background: #212432;
  color: #72f138;
}

.selectOption:checked + label:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #72f138;
  position: absolute;
  right: 10px;
  top: calc(50% - 2.5px);
  pointer-events: none;
  z-index: 7;
}

.selectOption:checked + label:before {
  position: absolute;
  right: 0;
  height: 40px;
  width: 40px;
  content: "";
  background: #212432;
}

.rowBtn,
.rowBtnActive {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  border-radius: 8px;
  text-transform: capitalize;
}

.rowBtn {
  background: #2f3245;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.375rem;
  color: #5f7381;
}

.rowBtnActive {
  background: #353749;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 1.375rem;
  color: #72f138;
}

.subWrapper {
  /* max-height: 200px; */
  overflow-y: scroll;
}

.modeButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
  gap: 10px;
}

.modeButtons > button {
  width: 100%;
}

.modeButtonActive {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 0;
  background: linear-gradient(
      180deg,
      #72f138 -98.76%,
      rgba(114, 241, 56, 0) 134.37%
    ),
    #72f138;
  border-radius: 8px;
}

.modeButtonActive > p, .modeButton > p {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #002800;
}

.modeButton {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px 0;
  background: #5f7381;
  border-radius: 8px;
}

@media only screen and (min-width: 400px) {
  .rowBtn,
  .rowBtnActive {
    font-size: 1rem;
  }
  .wrapper,
  .wrapperGame {
    max-width: 340px;
    width: 340px
  }
}
