.gif-wrapper {
    width: 70px;
    position: absolute;
    right: -100px;
    bottom: 60px;
    z-index: 2;
}

.gif-shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 60px 35px #00FF00;
    z-index: -1;
}

@media only screen and (min-width: 1201px) {
    .gif-wrapper {
        width: 100px;
        left: 0;
        bottom: 60px;
        z-index: auto;
    }

    .gif-shadow {
        box-shadow: 0 0 60px 45px #00FF00;
    }
}

