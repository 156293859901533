.towers-layers {
  display: flex;
  flex-direction: column;
  align-items: center;
  --gap: 15px;
  width: 100%;
  gap: var(--gap);
}

.towers-layer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: calc(5px + 1vw);
}

.towers-field {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.towers-field-live::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 0;
  height: 0;
  background: transparent;
  border: 2px solid transparent;
  border-radius: 8px;
  z-index: 100;
  animation: animate 0.5s linear forwards;
}

.towers-field-live::after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 0;
  height: 0;
  background: transparent;
  border: 2px solid transparent;
  border-radius: 8px;
  z-index: 100;
  animation: animates 0.5s linear forwards;
}

.towers-field-button {
  transition: 0.2s;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.towers-field-button:enabled:hover {
  opacity: 0.5;
}

.bomb-gif-wrapper {
  position: absolute;
  z-index: 2;
  top: -50px;
}

.towers-field > button > svg {
  width: 100%;
  border-radius: 8px;
}

@keyframes animate {
  0% {
    width: 0;
    height: 0;
    border-top-color: #72f138;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  50% {
    width: 100%;
    height: 0;
    border-top-color: #72f138;
    border-right-color: #72f138;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: #72f138;
    border-right-color: #72f138;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

@keyframes animates {
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #72f138;
  }

  50% {
    width: 0;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #72f138;
    border-left-color: #72f138;
  }

  100% {
    width: 100%;
    height: 100%;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #72f138;
    border-left-color: #72f138;
  }
}
