.wrapper {
  width: 5.625rem;
  height: 5.625rem;
  position: relative;
  transition: 0.2s;
  cursor: pointer;
}

.wrapper:enabled:hover {
  opacity: 0.5;
}

.wrapper img {
  width: 100%;
  height: 100%;
}

.bombgif {
  position: absolute;
  transform: translate(0, -90px);
  z-index: 2;
}

@media (max-width: 1200px) {
  .wrapper {
    width: 5.325rem;
    height: 5.325rem;
  }
}

@media (max-width: 590px) {
  .wrapper {
    width: 4.125rem;
    height: 4.125rem;
  }
}
