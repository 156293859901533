.multipliers-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  background: #252838;
  box-shadow: 0px 6px 0px #1f262e;
  border-radius: 12px;
  width: 100%;
  max-width: 290px;
}

.multipliers {
  min-height: 60px;
  padding: 7px;
  background: #212432;
  box-shadow: inset 0px -4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
}

.multiplier > img {
  height: 100%;
  width: 100%;
}
