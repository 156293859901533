.balance-box {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;

  /* max-width: 300px; */
  width: 100%;

  background: #252838;
  box-shadow: 0px 6px 0px #1f262e;
  border-radius: 12px;
  padding: 20px;
  z-index: 10;
}

.balance-box > p {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 15px;

  color: #5f7381;
}

.balance-box > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.balance-box > div > p {
  font-style: normal;
  font-weight: 700;
  font-size: 22.3644px;
  line-height: 30px;

  background: linear-gradient(180deg, #d3ff76 0%, #72f138 46.4%, #3bbd1b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 0px 3.99364px rgba(98, 255, 95, 0.8),
    0px 0px 23.9619px rgba(98, 255, 95, 0.3);
}

/* @media only screen and (min-width: 400px) {
  .balance-box {
    max-width: 340px;
  }
} */