@import "../../../node_modules/normalize.css/normalize.css";

#targetContainer {
  max-height: 610px;
  height: 80vw;
  width: 100%;
  margin: 0;
  background: #282d3e;
  border-radius: 8px;
  padding: 0;
  position: relative;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  font-size: 12px;
  background: url("./images/crashbg.png");
  background-position: center center;
  background-size: cover;
  &::before {
    content: "";
    display: block;
    background: #6b748b;
    position: absolute;
    left: 54px;
    bottom: 46px;
    width: 1px;
    height: calc(100% - 46px - 20px);
  }
  &::after {
    content: "";
    display: block;
    background: #6b748b;
    position: absolute;
    left: 54px;
    bottom: 46px;
    height: 1px;
    width: calc(100% - 54px - 20px);
  }
}

#taillist {
  position: absolute;
  width: 100%;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  div {
    transition: all 0.3s ease-in-out;
    color: #f8f8ff;
    background: rgba(248, 248, 255, 0.1);
    border-radius: 4px;
    margin: 0 0 0 5px;
    padding: 4px 8px;
    opacity: 0.01;
    &.hilighted {
      color: #72f138;
      background: rgba(114, 241, 56, 0.1);
      &:last-child {
        border: 2px solid rgba(114, 241, 56, 0.3);
      }
    }
    &.visibled {
      opacity: 1;
      animation-name: blink;
      animation-duration: 0.3s;
      animation-iteration-count: 1;
      animation-delay: 0.2s;
      animation-timing-function: ease-in-out;
    }
    &.deleted {
      transition-timing-function: ease-in;
      opacity: 0;
      margin-top: -20px;
    }
    &:last-child {
      border: 2px solid rgba(255, 255, 255, 0.3);
      margin: -2px -2px -2px 3px;
    }
  }
}

.crash-container {
  overflow-x: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.crash-main {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 5px 35px;
  // max-height: 100vh;
  // height: 100%;
  width: 95vw;

  gap: 20px;
}

.crash-data {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  order: 3;
  height: 100%;
}

.crash-left {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  order: 2;
}

@media (min-width: 1100px) {
  .crash-container {
    align-items: center;
  }
  .crash-left {
    order: 0;
    max-width: 340px;
  }
  .crash-main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    max-height: 610px;
    height: 100%;
    width: 100%;
  }
  #targetContainer {
    width: 70%;
    height: 100%;
  }
}

@keyframes blink {
  0% {
    background: rgba(255, 255, 255, 0);
  }
  30% {
    background: rgba(255, 255, 255, 1);
  }
  100% {
    background: rgba(248, 248, 255, 0.1);
  }
}
