.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.wrapper table {
  width: 56rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.menuBalanceWrapper {
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 10px 12px 10px;
  background: #1c1c29;
  gap: 25px;
  width: 100%;
}

.menuBalance {
  background: #222835;
  border-radius: 8px 0px 0px 8px;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.menuBalance h4 {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  margin-left: 10px;
  display: flex;
  align-items: center;
  color: #f8f8ff;
}

.menuBalance img:first-child {
  width: 26px;
  height: 26px;
}

.menuBalance img:last-child {
  margin-left: 50px;
}

.menuBalanceBtn {
  background: #72f138;
  box-shadow: inset 0px -4px 0px #53a92c;
  border-radius: 0px 8px 8px 0px;
  width: 48px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.regularBtn {
  width: 45px;
  height: 45px;
  background: #222835;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 900px) {
  .wrapper table {
    width: 100vw;
  }
}
