.wrapper,
.wrapperMin {
    display: flex;

    width: 56rem;
    background: #16171F;
    border-radius: 12px;
}

.wrapper {
    align-items: center;
    height: 37.4rem;
}

.wrapperMin {
    flex-direction: column;
    width: 100%;
}

.leftSide {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 50px 20px 0;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background: #5F7381;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.leftSideMin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    background: #5F7381;
    width: 100%;
    height: 25rem;
}

.rightSide {
    background: #16171F;
    border-radius: 12px;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 55px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftBottomPic,
.leftTopPic {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    position: absolute;
    width: 100%;
}

.leftTopPic {
    top: 0;
    left: 0;
    z-index: 0;
}

.leftBottomPic {
    bottom: 0;
    left: 0;
}

.cubGroup {
    position: absolute;
    left: -21px;
    /* transform: rotate(1.3deg); */
    z-index: 1;
    /* top: 52px; */
    height: 37.6rem;
}

.cubGroupMin {
    position: relative;
    /* left: 0; */
    /* transform: rotate(-90deg); */
    /* transform: rotate(1.3deg); */
    z-index: 1;
    right: 0;
    /* top: 48px; */
    width: 98vw;
}


.cubWrapper {
    margin-left: 30px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    grid-template-rows: repeat(5, 1fr);
}

@media (max-width: 960px) {
    .cubGroup {
        top: 124px;
        transform: rotate(-90deg);
        z-index: 1;
        left: 42%;
        height: 93vw;
    }

    .cubWrapper {
        margin-left: 0;
        justify-items: start;
    }

    .rightSide {
        flex-direction: column;
        padding: 0;
        height: 100%;
        margin-top: 35px;
        align-items: center;
    }
}