.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
}

.balls {
  display: flex;
}

.ball {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin-right: 10px;
  animation: ball 0.5s ease infinite alternate;
}

.ball2 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin-right: 10px;
  animation: ball 0.5s ease infinite alternate;
  animation-delay: 0.1s;
}

.ball3 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin-right: 10px;
  animation: ball 0.5s ease infinite alternate;
  animation-delay: 0.2s;
}

@keyframes ball {
  to {
    transform: translateY(-10px);
  }
}

.customtext {
  font-size: 20px;
  color: white;
}

.bottom {
  flex-direction: column;
}

.right {
  flex-direction: row-reverse;
}

.loader.isSmall {
  margin-top: 6px;
  width: auto;

  gap: 10px;

  .balls {
    margin-top: 9px;
  }

  .ball,
  ball2 {
    margin-left: 7;
  }

  .ball,
  .ball2,
  .ball3 {
    width: 7px;
    height: 7px;
  }
}
